<template>
  <div>
    <Navbar title="" :hideSearch="false" />
    <div class="image-heading">
      <p class="text-heading bold has-text-white">
        ABOUT READERS LOUNGE
      </p>
    </div>
    <div class="app-body my-5 py-5">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="text-heading bold">
            About
          </p>
          <p class="mt-5">
            Readers Lounge is an initiative of Islamabad Policy Research
            Institute to promote equal access of quality information. It is a
            center of learning and research that stimulates creativity,
            learning, and discovery.
          </p>
          <p class="mt-5">
            Readers Lounge provides a place for students and researchers to
            learn and appreciate the power of information. A variety of digital
            collection are also being gathered and catalogued to ensure
            proficiency in their respective realms of study.
          </p>
          <p class="mt-5">
            We develop, organize, provide access to and preserve materials to
            meet the needs of present and future generations of students and
            scholars. We explore and implement innovative technologies and
            services to deliver information and scholarly resources conveniently
            to users anytime/anyplace.
          </p>
          <p class="text-heading bold mt-5 pt-5">
            Contact Us
          </p>
          <p class="mt-5 mb-5 pb-5">
            In case of any query, kindly email us at
            <a href="mailto:readerslounge@ipripak.org">
              readerslounge@ipripak.org
            </a>
            or learn more about IPRI at
            <a href="http://www.ipripak.org" target="_blank">
              www.ipripak.org.
            </a>
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/ipri.png" width="80%" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";

export default {
  name: "About",
  components: { Navbar, Footer },
  data() {
    return {};
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    window.scrollTo(0, 0);
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.image-heading {
  padding: 10vh;
  background: url("../../assets/splash.jpeg");
  text-align: center;
}
</style>
