<template>
  <div>
    <div class="columns is-gapless">
      <div class="column is-5" style="max-height:100vh; overflow-y: scroll;">
        <div style="padding: 5em 0em 5em 5em!important;">
          <router-link
            :to="{
              name: 'Home'
            }"
          >
            <img src="@/assets/logo.png" width="240px" />
          </router-link>
          <div style="margin-top: 5vh; width: 70%">
            <h3 class="title">Sign up to Readers Lounge</h3>
            <p>
              <router-link
                :to="{
                  name: 'Login'
                }"
              >
                <a>
                  Already have an account? Sign in here.
                </a>
              </router-link>
            </p>
            <div class="mt-4">
              <div v-for="(errorMessage, index) in errorMessages" :key="index">
                <b-message v-if="showErrorMessage" type="is-danger"
                  ><i class="mdi mdi-information-outline pr-2"></i>
                  {{ errorMessage }}</b-message
                >
              </div>

              <b-message v-if="showPasswordIncorrectMessage" type="is-danger"
                ><i class="mdi mdi-information-outline pr-2"></i> Passwords do
                not match</b-message
              >
              <form @submit.prevent="register" id="registerForm">
                <b-field label="Name">
                  <b-input
                    v-model="registerDetails.firstName"
                    placeholder="First Name"
                    type="text"
                    required
                  ></b-input>
                  <b-input
                    v-model="registerDetails.lastName"
                    placeholder="Last Name"
                    type="text"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Email">
                  <b-input
                    v-model="registerDetails.email"
                    placeholder="Enter your email address"
                    type="email"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Phone">
                  <b-input
                    v-model="registerDetails.contact"
                    placeholder="03001234567"
                    type="number"
                    required
                    autocomplete="new-password"
                  ></b-input>
                </b-field>
                <b-field label="Password">
                  <b-input
                    v-model="registerDetails.password"
                    type="password"
                    placeholder="Enter your password"
                    required
                    autocomplete="new-password"
                  ></b-input>
                </b-field>
                <b-field label="Confirm Password">
                  <b-input
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Enter your password again"
                    required
                    autocomplete="new-password"
                  ></b-input>
                </b-field>

                <button
                  form="registerForm"
                  class="button is-primary mt-4"
                  type="submit"
                  :disabled="isLogginLoading"
                >
                  {{ isLogginLoading ? "Please wait" : "Register" }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-fullheight img-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      registerDetails: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        contact: undefined,
        public: true
      },
      confirmPassword: undefined,
      isLogginLoading: false,
      showErrorMessage: false,
      showPasswordIncorrectMessage: false,
      errorMessages: undefined
    };
  },
  methods: {
    async register() {
      this.showPasswordIncorrectMessage = false;
      this.showErrorMessage = false;
      this.errorMessages = undefined;
      this.isLogginLoading = true;
      this.registerDetails["role"] = "free";
      if (this.confirmPassword !== this.registerDetails.password) {
        this.showPasswordIncorrectMessage = true;
        this.isLogginLoading = false;
        return;
      }
      const result = await this.$store.dispatch(
        "addUser",
        this.registerDetails
      );
      if (result.errorMessages) {
        this.showErrorMessage = true;
        this.errorMessages = result.errorMessages;
      } else {
        this.$router.push({ name: "Home" });
      }
      this.isLogginLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.img-bg {
  background: url("../../assets/splash.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
</style>
