var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-gapless"},[_c('div',{staticClass:"column is-5",staticStyle:{"max-height":"100vh","overflow-y":"scroll"}},[_c('div',{staticStyle:{"padding":"5em 0em 5em 5em!important"}},[_c('router-link',{attrs:{"to":{
            name: 'Home'
          }}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"width":"240px"}})]),_c('div',{staticStyle:{"margin-top":"5vh","width":"70%"}},[_c('h3',{staticClass:"title"},[_vm._v("Log In to Readers Lounge")]),_c('p',[_vm._v(" Log in to your account to access exclusive content ")]),_c('div',{staticClass:"mt-4"},[(_vm.showErrorMessage)?_c('b-message',{attrs:{"type":"is-danger"}},[_c('i',{staticClass:"mdi mdi-information-outline pr-2"}),_vm._v(" Incorrect username or password")]):_vm._e(),_c('form',{attrs:{"id":"loginForm"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-field',{attrs:{"label":"Email"}},[_c('b-input',{attrs:{"placeholder":"Enter your email address","type":"email","required":""},model:{value:(_vm.loginDetails.email),callback:function ($$v) {_vm.$set(_vm.loginDetails, "email", $$v)},expression:"loginDetails.email"}})],1),_c('b-field',{attrs:{"label":"Password"}},[_c('b-input',{attrs:{"type":"password","placeholder":"Enter your password","required":""},model:{value:(_vm.loginDetails.password),callback:function ($$v) {_vm.$set(_vm.loginDetails, "password", $$v)},expression:"loginDetails.password"}})],1),_c('p',[_c('router-link',{attrs:{"to":{
                    name: 'ForgotPassword'
                  }}},[_c('a',[_vm._v(" Forgot password? ")])])],1),_c('div',{staticClass:"columns is-vcentered mt-5"},[_c('div',{staticClass:"column is-narrow"},[_c('button',{staticClass:"button is-primary",attrs:{"form":"loginForm","type":"submit","disabled":_vm.isLogginLoading}},[_vm._v(" "+_vm._s(_vm.isLogginLoading ? "Logging In" : "Log In")+" ")])]),_c('div',{staticClass:"column"},[_c('router-link',{attrs:{"to":{
                      name: 'Register'
                    }}},[_c('a',[_vm._v(" Create a new account ")])])],1)])],1)],1)])],1)]),_c('div',{staticClass:"column is-fullheight img-bg"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }