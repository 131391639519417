<template>
  <div>
    <div class="columns is-gapless">
      <div class="column is-5" style="max-height:100vh; overflow-y: scroll;">
        <div style="padding: 5em 0em 5em 5em!important;">
          <router-link
            :to="{
              name: 'Home'
            }"
          >
            <img src="@/assets/logo.png" width="240px" />
          </router-link>
          <div style="margin-top: 5vh; width: 70%">
            <h3 class="title">Log In to Readers Lounge</h3>
            <p>
              Log in to your account to access exclusive content
            </p>
            <div class="mt-4">
              <b-message v-if="showErrorMessage" type="is-danger"
                ><i class="mdi mdi-information-outline pr-2"></i> Incorrect
                username or password</b-message
              >
              <form @submit.prevent="login" id="loginForm">
                <b-field label="Email">
                  <b-input
                    v-model="loginDetails.email"
                    placeholder="Enter your email address"
                    type="email"
                    required
                  ></b-input>
                </b-field>
                <b-field label="Password">
                  <b-input
                    v-model="loginDetails.password"
                    type="password"
                    placeholder="Enter your password"
                    required
                  ></b-input>
                </b-field>
                <p>
                  <router-link
                    :to="{
                      name: 'ForgotPassword'
                    }"
                  >
                    <a>
                      Forgot password?
                    </a>
                  </router-link>
                </p>

                <div class="columns is-vcentered mt-5">
                  <div class="column is-narrow">
                    <button
                      form="loginForm"
                      class="button is-primary "
                      type="submit"
                      :disabled="isLogginLoading"
                    >
                      {{ isLogginLoading ? "Logging In" : "Log In" }}
                    </button>
                  </div>
                  <div class="column">
                    <router-link
                      :to="{
                        name: 'Register'
                      }"
                    >
                      <a>
                        Create a new account
                      </a>
                    </router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-fullheight img-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loginDetails: {
        email: undefined,
        password: undefined
      },
      isLogginLoading: false,
      showErrorMessage: false
    };
  },
  methods: {
    async login() {
      this.isLogginLoading = true;
      const result = await this.$store.dispatch("login", this.loginDetails);
      if (!result) {
        this.showErrorMessage = true;
      }
      this.isLogginLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.img-bg {
  background: url("../../assets/splash.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
</style>
