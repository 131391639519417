<template>
  <div>
    <Navbar title="" :hideSearch="false" />
    <div class="app-body my-5 py-5">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="text-heading bold">
            Reset Password
          </p>
          <p class="mt-5">
            You can reset your password by sending an email to

            <a href="mailto:readerslounge@ipripak.org">
              readerslounge@ipripak.org
            </a>

            from the email account you signed up with and system administrator
            will send you a temporary password to login with.
          </p>
          <p class="mt-5">
            Once logged in, you can change your password by clicking on your
            name in the top navigation bar and selecting "Change Password".
          </p>
          <p class="text-heading bold mt-5 pt-5">
            Contact Us
          </p>
          <p class="mt-5 mb-5 pb-5">
            In case of any query, kindly email us at
            <a href="mailto:readerslounge@ipripak.org">
              readerslounge@ipripak.org
            </a>
            or learn more about IPRI at
            <a href="http://www.ipripak.org" target="_blank">
              www.ipripak.org.
            </a>
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/ipri.png" width="80%" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";

export default {
  name: "About",
  components: { Navbar, Footer },
  data() {
    return {};
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  async created() {
    window.scrollTo(0, 0);
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.image-heading {
  padding: 10vh;
  background: url("../../assets/splash.jpeg");
  text-align: center;
}
</style>
